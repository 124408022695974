import { Features, Feature, ComponentExample, RelatedComponents, FeatureDescription } from 'docs';
import { RadioGroup, RadioButton } from '@pleo-io/telescope';
import * as React from 'react';
export default {
  Features,
  Feature,
  ComponentExample,
  RelatedComponents,
  FeatureDescription,
  RadioGroup,
  RadioButton,
  React
};