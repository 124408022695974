import React from 'react'

import {Text} from '@pleo-io/telescope'

const featureDescriptions = {
    skeleton:
        'The skeleton property is used to display a loading state for the component while data is being fetched. It provides a visual placeholder, giving users a preview of the UI structure before the actual content is loaded. This enhances the user experience by offering a more accurate representation of the final layout during data retrieval.'
}

type FeatureDescriptionProps = {
    feature: keyof typeof featureDescriptions
}

export const FeatureDescription = ({feature}: FeatureDescriptionProps) => (
    <Text>{featureDescriptions[feature]}</Text>
)
