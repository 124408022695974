import { RawPropsTable, Props } from 'docs';
import { Button, NakedButton, Popover } from '@pleo-io/telescope';
import * as React from 'react';
export default {
  RawPropsTable,
  Props,
  Button,
  NakedButton,
  Popover,
  React
};