import { ComponentExample, DosAndDonts, Features, Feature, RelatedComponents } from 'docs';
import { Button, Link, List, ListItem, ModalSplit, Stack, Tag, useModalSplitSteps } from '@pleo-io/telescope';
import { Check } from '@pleo-io/telescope-icons';
import actionbanners from '@/assets/modal-split/actionbanners.png';
import browser from '@/assets/modal-split/browser-screenshot-pocket.png';
import clock from '@/assets/modal-split/clock.svg';
import filters from '@/assets/modal-split/filters.svg';
import mobile from '@/assets/modal-split/mobile-screenshot-expenses.png';
import place from '@/assets/modal-split/place.png';
import pocket from '@/assets/modal-split/pocket.svg';
import reimbursement from '@/assets/modal-split/reimbursement.svg';
import reimbursements from '@/assets/modal-split/reimbursements.svg';
import photo from '@/assets/modal-split/photo.jpg';
import sort from '@/assets/modal-split/sort.svg';
import tabs from '@/assets/modal-split/tabs.png';
import trial from '@/assets/modal-split/trial.svg';
import * as React from 'react';
export default {
  ComponentExample,
  DosAndDonts,
  Features,
  Feature,
  RelatedComponents,
  Button,
  Link,
  List,
  ListItem,
  ModalSplit,
  Stack,
  Tag,
  useModalSplitSteps,
  Check,
  actionbanners,
  browser,
  clock,
  filters,
  mobile,
  place,
  pocket,
  reimbursement,
  reimbursements,
  photo,
  sort,
  tabs,
  trial,
  React
};