import { Features, Feature, ComponentExample, RelatedComponents, FeatureDescription } from 'docs';
import { IconButton, Stack } from '@pleo-io/telescope';
import { Close, Options } from '@pleo-io/telescope-icons';
import * as React from 'react';
export default {
  Features,
  Feature,
  ComponentExample,
  RelatedComponents,
  FeatureDescription,
  IconButton,
  Stack,
  Close,
  Options,
  React
};