import React from 'react'
import styled from 'styled-components'

import {Text, tokens} from '@pleo-io/telescope'
import {ChevronRight} from '@pleo-io/telescope-icons'

const Wrapper = styled.a`
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: ${tokens.spacing24};
    padding: ${tokens.spacing24};
    color: ${tokens.colorContentStatic};
    text-align: left;
    background-color: ${tokens.shade000};
    border: ${tokens.borderInteractiveQuiet};
    border-radius: ${tokens.arc8};
    cursor: pointer;
    transition: ${tokens.smooth};

    &:hover {
        color: ${tokens.colorContentStatic};
        text-decoration: none;
        box-shadow: ${tokens.shadowRaise};
    }
`

const Illustration = styled.div`
    margin-right: ${tokens.spacing24};
    color: ${tokens.colorContentStatic};
`

const Content = styled.div`
    flex: 1;
`

const Title = styled(Text).attrs({variant: 'medium-default', weight: 'semibold'})``

const Description = styled(Text).attrs({variant: 'medium-default'})`
    color: ${tokens.colorContentStaticQuiet};
`

type Props = {
    title: string
    description: string
    href: string
    illustration?: any
}

export const Linkbox = ({title, description, illustration, href}: Props) => (
    <Wrapper href={href} target="_blank">
        {illustration && <Illustration>{illustration}</Illustration>}
        <Content>
            <Title>{title}</Title>
            <Description>{description}</Description>
        </Content>
        <ChevronRight />
    </Wrapper>
)
