import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'

export const FancyList = styled.div`
    margin: ${tokens.spacing24} 0;
    padding: ${tokens.spacing36};
    background: ${tokens.colorBackgroundStaticLoud};
    border: ${tokens.borderStatic};
    border-radius: 12px; /* stylelint-disable-line declaration-property-value-allowed-list */

    li:last-child {
        /* Note, this really should be fixed in Telescope... */
        margin-bottom: 0;
    }
`
