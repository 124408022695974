import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'

import {fontFamilyCode} from '@/tokens'

export const Key = styled.kbd`
    padding-top: ${tokens.spacing2};
    padding-right: ${tokens.spacing6};
    padding-bottom: ${tokens.spacing2};
    padding-left: ${tokens.spacing6};
    font-weight: ${tokens.fontWeightMedium};
    font-size: ${tokens.fontSmall};
    font-family: ${fontFamilyCode};
    background-color: ${tokens.colorBackgroundStaticLoudest};
    border: ${tokens.borderStatic};
    border-radius: ${tokens.arc4};
`
