import { DoExample, DontExample, Figure, Columns, Column, StaticImage } from 'docs';
import * as React from 'react';
export default {
  DoExample,
  DontExample,
  Figure,
  Columns,
  Column,
  StaticImage,
  React
};