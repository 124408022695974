import { Features, Feature, FeatureDescription, ComponentExample } from 'docs';
import { Avatar, AvatarGroup, Inline, tokens } from '@pleo-io/telescope';
import { CategoryEntertainment, SpeechBubble, Wallet, MinusCircle, TrashClock } from '@pleo-io/telescope-icons';
import jeppe from '@/assets/jeppe.png';
import niccolo from '@/assets/niccolo.png';
import * as React from 'react';
export default {
  Features,
  Feature,
  FeatureDescription,
  ComponentExample,
  Avatar,
  AvatarGroup,
  Inline,
  tokens,
  CategoryEntertainment,
  SpeechBubble,
  Wallet,
  MinusCircle,
  TrashClock,
  jeppe,
  niccolo,
  React
};