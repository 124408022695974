import { Props, Placeholder, ComponentExample, Features, Feature, RelatedComponents } from 'docs';
import { Inline } from '@pleo-io/telescope';
import * as React from 'react';
export default {
  Props,
  Placeholder,
  ComponentExample,
  Features,
  Feature,
  RelatedComponents,
  Inline,
  React
};