import {styled} from 'styled-components'

import tokens from '@pleo-io/telescope-tokens'

export const Column = styled.div`
    flex: 1;
`

export const Columns = styled.div`
    display: flex;
    gap: ${tokens.spacing36};
    margin-top: ${tokens.spacing24};

    & + & {
        margin-top: ${tokens.spacing60};
    }
`
