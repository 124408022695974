import { Features, Feature, ComponentExample, RelatedComponents, FeatureDescription } from 'docs';
import { Checkbox, CheckboxGroup, FormControl } from '@pleo-io/telescope';
import * as React from 'react';
export default {
  Features,
  Feature,
  ComponentExample,
  RelatedComponents,
  FeatureDescription,
  Checkbox,
  CheckboxGroup,
  FormControl,
  React
};