import { Features, Feature, ComponentExample, RelatedComponents, FeatureDescription } from 'docs';
import { Inline, Input, BaseError, BaseInput, FormikInput } from '@pleo-io/telescope';
import { Search } from '@pleo-io/telescope-icons';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import * as React from 'react';
export default {
  Features,
  Feature,
  ComponentExample,
  RelatedComponents,
  FeatureDescription,
  Inline,
  Input,
  BaseError,
  BaseInput,
  FormikInput,
  Search,
  Formik,
  Form,
  yup,
  React
};