import React from 'react'
import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'
import opacity from '@pleo-io/telescope-tokens/dist/opacity.json'

import {
    Table,
    TableHead,
    TableHeadRow,
    TableHeadData,
    TableBody,
    TableBodyRow,
    TableBodyData,
    TokenName
} from '@/components/docs/table'
import {backgroundColorExample} from '@/tokens'

const Example = styled.div<{opacity: number}>`
    height: ${tokens.spacing20};
    background-color: ${backgroundColorExample};
    border-radius: ${tokens.arc4};
    opacity: ${({opacity}) => opacity};
`

export const Opacity = () => {
    return (
        <Table>
            <TableHead>
                <TableHeadRow>
                    <TableHeadData>Token</TableHeadData>
                    <TableHeadData>Alpha</TableHeadData>
                    <TableHeadData>Example</TableHeadData>
                </TableHeadRow>
            </TableHead>
            <TableBody>
                {opacity.props.map((token) => (
                    <TableBodyRow key={token.name}>
                        <TableBodyData>
                            <TokenName {...token} />
                        </TableBodyData>
                        <TableBodyData>{token.value}</TableBodyData>
                        <TableBodyData>
                            <Example opacity={token.value} />
                        </TableBodyData>
                    </TableBodyRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default Opacity
