import { ComponentExample, Features, Feature, RelatedComponents, DosAndDonts, FeatureDescription } from 'docs';
import { Badge } from '@pleo-io/telescope';
import { ThumbUp, Pen, DiamondHalf, EmotionHushed, ThumbDown, Warning, Info, Rocket } from '@pleo-io/telescope-icons';
import * as React from 'react';
export default {
  ComponentExample,
  Features,
  Feature,
  RelatedComponents,
  DosAndDonts,
  FeatureDescription,
  Badge,
  ThumbUp,
  Pen,
  DiamondHalf,
  EmotionHushed,
  ThumbDown,
  Warning,
  Info,
  Rocket,
  React
};