import React from 'react'
import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'
import colorSchemeDark from '@pleo-io/telescope-tokens/dist/color-scheme-dark.json'
import colorSchemeLight from '@pleo-io/telescope-tokens/dist/color-scheme-light.json'
import color from '@pleo-io/telescope-tokens/dist/color.json'

import {
    Table,
    TableHead,
    TableHeadRow,
    TableHeadData,
    TableBody,
    TableBodyRow,
    TableBodyData,
    TokenName
} from '@/components/docs/table'
import {useThemeSwitcher} from '@/components/theme-switcher/theme-switcher'

const Example = styled.span<{$backgroundColor: string}>`
    display: inline-flex;
    border: ${tokens.borderStaticLoud};
    height: 20px;
    width: 100%;
    background-color: ${({$backgroundColor}) => $backgroundColor};
    border-radius: ${tokens.arc4};
    text-align: right;
`

export const CoreColors = () => {
    return (
        <Table>
            <TableHead>
                <TableHeadRow>
                    <TableHeadData>Token</TableHeadData>
                    <TableHeadData>Value</TableHeadData>
                    <TableHeadData css={{width: '100px'}}>Example</TableHeadData>
                </TableHeadRow>
            </TableHead>
            <TableBody>
                {color.props.map(({name, value}) => (
                    <TableBodyRow key={name}>
                        <TableBodyData>
                            <TokenName name={name} />
                        </TableBodyData>
                        <TableBodyData>{value}</TableBodyData>
                        <TableBodyData>
                            <Example $backgroundColor={value} />
                        </TableBodyData>
                    </TableBodyRow>
                ))}
            </TableBody>
        </Table>
    )
}

export const SemanticColors = () => {
    const {theme} = useThemeSwitcher()
    const colorScheme = theme === 'light' ? colorSchemeLight : colorSchemeDark
    return (
        <Table>
            <TableHead>
                <TableHeadRow>
                    <TableHeadData>Token</TableHeadData>
                    <TableHeadData css={{width: '100px'}}>Example</TableHeadData>
                </TableHeadRow>
            </TableHead>
            <TableBody>
                {colorScheme.props
                    .filter((c) => c.category === 'color')
                    .map((token) => (
                        <TableBodyRow key={token.name}>
                            <TableBodyData>
                                <TokenName {...token} />
                            </TableBodyData>
                            <TableBodyData>
                                <Example $backgroundColor={token.value} />
                            </TableBodyData>
                        </TableBodyRow>
                    ))}
            </TableBody>
        </Table>
    )
}
