import React from 'react'
import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'
import fontSize from '@pleo-io/telescope-tokens/dist/font-size.json'
import fontWeight from '@pleo-io/telescope-tokens/dist/font-weight.json'
import lineHeight from '@pleo-io/telescope-tokens/dist/line-height.json'

import {
    Table,
    TableHead,
    TableHeadRow,
    TableHeadData,
    TableBody,
    TableBodyRow,
    TableBodyData,
    TokenName
} from '@/components/docs/table'

const Size = styled.div<{value: string | number}>`
    font-size: ${({value}) => value};
    line-height: ${tokens.lineHeight1};
`

const LineHeightExample = styled.div<{value: number}>`
    line-height: ${({value}) => value};
    background-color: ${tokens.colorBackgroundStaticLoudest};
`

export const FontSize = () => (
    <Table>
        <TableHead>
            <TableHeadRow>
                <TableHeadData>Token</TableHeadData>
                <TableHeadData>Value</TableHeadData>
                <TableHeadData>Example</TableHeadData>
            </TableHeadRow>
        </TableHead>
        <TableBody>
            {fontSize.props.map((token) => (
                <TableBodyRow key={token.name}>
                    <TableBodyData>
                        <TokenName {...token} />
                    </TableBodyData>
                    <TableBodyData>{token.value}</TableBodyData>
                    <TableBodyData>
                        <Size value={token.value}>Telescope</Size>
                    </TableBodyData>
                </TableBodyRow>
            ))}
        </TableBody>
    </Table>
)

const Weight = styled.div<{value: number}>`
    font-weight: ${({value}) => value};
    font-size: ${tokens.font3XLarge};
`

export const FontWeight = () => (
    <Table>
        <TableHead>
            <TableHeadRow>
                <TableHeadData>Token</TableHeadData>
                <TableHeadData>Weight</TableHeadData>
                <TableHeadData>Example</TableHeadData>
            </TableHeadRow>
        </TableHead>
        <TableBody>
            {fontWeight.props
                .sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0))
                .map((token) => (
                    <TableBodyRow key={token.name}>
                        <TableBodyData>
                            <TokenName {...token} />
                        </TableBodyData>
                        <TableBodyData>{token.value}</TableBodyData>
                        <TableBodyData>
                            <Weight value={token.value}>Aa</Weight>
                        </TableBodyData>
                    </TableBodyRow>
                ))}
        </TableBody>
    </Table>
)

export const LineHeight = () => (
    <Table>
        <TableHead>
            <TableHeadRow>
                <TableHeadData>Token</TableHeadData>
                <TableHeadData>Value</TableHeadData>
                <TableHeadData>Example</TableHeadData>
            </TableHeadRow>
        </TableHead>
        <TableBody>
            {lineHeight.props.map((token) => (
                <TableBodyRow key={token.name}>
                    <TableBodyData>
                        <TokenName {...token} />
                    </TableBodyData>
                    <TableBodyData>{token.value}</TableBodyData>
                    <TableBodyData>
                        <LineHeightExample value={token.value}>Telescope</LineHeightExample>
                    </TableBodyData>
                </TableBodyRow>
            ))}
        </TableBody>
    </Table>
)

export default FontSize
