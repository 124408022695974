import {useMDXScope} from 'gatsby-plugin-mdx/context'
import Highlight, {defaultProps} from 'prism-react-renderer'
import React, {useState} from 'react'
import {LiveProvider, LiveEditor, LiveError, LivePreview} from 'react-live'
import styled, {css} from 'styled-components'
import ts, {transpile} from 'typescript'

import {NakedButton, focusRing, tokens} from '@pleo-io/telescope'

import {fontFamilyCode} from '@/tokens'

import theme from './prisma-theme'

const transformCode = (snippet: string, target: ts.ScriptTarget) =>
    transpile(snippet, {jsx: ts.JsxEmit.React, target})

const borderRadius = tokens.arc8

const Wrapper = styled.div`
    margin-bottom: ${tokens.spacing48};
`

export const StyledEditor = styled(LiveEditor)`
    width: 100%;
    border-bottom-right-radius: ${borderRadius};
    border-bottom-left-radius: ${borderRadius};
    border: ${tokens.borderStatic};
    border-top: none;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    font-size: 13px;
    font-family: ${fontFamilyCode} !important;
    -webkit-font-smoothing: auto;

    textarea:focus {
        ${focusRing('inset')}
        border-bottom-right-radius: ${borderRadius};
        border-bottom-left-radius: ${borderRadius};
    }
`

const PreviewContainer = styled.div`
    position: relative;
`

const CodeButton = styled(NakedButton)`
    ${focusRing('inset')}
    position: absolute;
    bottom: 0;
    right: 0;
    height: 24px;
    width: 92px;
    padding: 0 ${tokens.spacing12};
    font-weight: ${tokens.fontWeightSemibold};
    font-size: ${tokens.fontSmall};
    color: ${tokens.colorContentInteractive};
    background: ${tokens.colorBackgroundInteractive};
    border-radius: ${tokens.arc8} 0 ${tokens.arc8} 0;
    border: ${tokens.borderInteractiveQuiet};
    transition: color ${tokens.fastInOut}, background-color ${tokens.fastInOut};

    &:hover {
        background-color: ${tokens.colorBackgroundInteractiveQuietHover};
        color: ${tokens.colorContentInteractiveHover};
    }
`

const StyledPreview = styled(LivePreview)<{
    $overflowXAuto?: boolean
    $showCode: boolean
}>`
    width: 100%;
    padding: ${tokens.spacing24} ${tokens.spacing24} ${tokens.spacing32};
    border: ${tokens.borderStatic};
    border-radius: ${borderRadius};
    overflow-x: ${({$overflowXAuto}) => ($overflowXAuto ? 'auto' : 'initial')};
    margin-top: ${tokens.spacing12};

    ${({$showCode}) =>
        $showCode &&
        css`
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        `}
`

// TODO: Find better way to determine the start of the last React component
// in the code block. Will break if there are nested components for instance.
const getIndexOfComponentToRender = (code: string) => code.match(/\n</)?.index
// TODO: This only works for single line import declarations for now.
const removeAllImportDeclarations = (code: string) => code.replaceAll(/import .*[^\n]/g, '')
// TODO: This is a workaround to allow using the `css` prop from styled-components
// in documentation examples, which we haven't managed to get working in gatsby mdx
// files yet. So it basically allow us to make the docs look like we want,
// but transforming the code to "actual" inline styles before transpiling
const replaceCssWithInlineStyle = (code: string) => code.replaceAll('css={', 'style={')

export const Code = ({
    codeString,
    language,
    live,
    noInline,
    hideCode = true,
    overflowXAuto,
    liveExperimental,
    ...props
}: any) => {
    const components = useMDXScope()
    const [showCode, setShowCode] = useState(!hideCode)

    if (!codeString) {
        return <code>{props.children}</code>
    }

    if (live || liveExperimental) {
        return (
            <Wrapper>
                <LiveProvider
                    code={codeString}
                    scope={components}
                    language={language}
                    theme={theme}
                    noInline={noInline || liveExperimental}
                    transformCode={(code) => {
                        if (liveExperimental && !code.match(/render\(/)) {
                            const indexOfComponentToRender = getIndexOfComponentToRender(code)
                            const start = code.slice(0, indexOfComponentToRender)
                            const end = code.slice(indexOfComponentToRender, code.length).trim()
                            // Wrap the last component expression in the `render` method.
                            const codeWithRender = `${start}render(${end})`
                            // Remove all import declarations in the example code block. These
                            // need to be available in the global scope anyways
                            const codeWithoutImports = removeAllImportDeclarations(codeWithRender)
                            const codeWithInlineStyles =
                                replaceCssWithInlineStyle(codeWithoutImports)
                            return transformCode(codeWithInlineStyles, ts.ScriptTarget.ES2015)
                        }
                        const codeWithInlineStyles = replaceCssWithInlineStyle(code)
                        return transformCode(codeWithInlineStyles, ts.ScriptTarget.ES2015)
                    }}
                >
                    <LiveError />
                    <PreviewContainer>
                        <StyledPreview $overflowXAuto={overflowXAuto} $showCode={showCode} />
                        <CodeButton aria-expanded={showCode} onClick={() => setShowCode(!showCode)}>
                            {showCode ? 'Hide code' : 'Show code'}
                        </CodeButton>
                    </PreviewContainer>
                    {/* @ts-expect-error padding does apply, but not accepted as prop for LiveEditor. Tried using `style` prop instead, but doesn't work the same. */}
                    {showCode && <StyledEditor padding={tokens.spacing24} />}
                </LiveProvider>
            </Wrapper>
        )
    } else {
        return (
            <Highlight {...defaultProps} code={codeString} language={language} theme={theme}>
                {({className, style, tokens: highlightTokens, getLineProps, getTokenProps}) => (
                    <pre
                        className={className}
                        style={{...style, margin: `${tokens.spacing12} 0 ${tokens.spacing36} 0`}}
                    >
                        {highlightTokens.map((line, i) => (
                            <div key={i} {...getLineProps({line, key: i})}>
                                {line.map((token, key) => (
                                    <span key={key} {...getTokenProps({token, key})} />
                                ))}
                            </div>
                        ))}
                    </pre>
                )}
            </Highlight>
        )
    }
}
