import {useStaticQuery, graphql} from 'gatsby'

import type {IllustrationsQuery} from '@/graphql-types'
import {sort} from '@/utilities/sort'

export const useIllustrations = () => {
    const data = useStaticQuery<IllustrationsQuery>(graphql`
        query Illustrations {
            components: allSitePage(filter: {path: {regex: "/illustrations/.+(\\.overview)/"}}) {
                nodes {
                    id
                    path
                    context {
                        frontmatter {
                            title
                            intro
                        }
                    }
                }
            }
        }
    `)

    const illustrations = data.components.nodes
        .map((illustration) => {
            const meta = illustration.context.frontmatter
            return {
                path: illustration.path,
                title: meta.title,
                intro: meta.intro
            }
        })
        .filter((c) => !c.path.match('overview.overview'))

    return illustrations.sort(sort((c) => c.title.toLowerCase()))
}
