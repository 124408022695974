import type {PrismTheme} from 'prism-react-renderer'

import {tokens} from '@pleo-io/telescope'

import {customColorSchemeTokens} from '@/tokens'

const theme: PrismTheme = {
    plain: {
        color: tokens.colorContentStatic,
        backgroundColor: tokens.colorBackgroundStaticLoud
    },
    styles: [
        {
            types: ['comment', 'prolog', 'doctype', 'cdata'],
            style: {
                color: tokens.colorContentStaticQuiet
            }
        },
        {
            types: ['property', 'tag', 'boolean', 'number', 'constant', 'symbol', 'deleted'],
            style: {
                color: customColorSchemeTokens.colorContentCodePreviewA
            }
        },
        {
            types: ['punctuation', 'attr-value'],
            style: {
                color: customColorSchemeTokens.colorContentCodePreviewA
            }
        },
        {
            types: ['selector', 'attr-name', 'string', 'char', 'builtin', 'inserted'],
            style: {
                color: customColorSchemeTokens.colorContentCodePreviewB
            }
        },
        {
            types: ['operator', 'entity', 'url'],
            style: {
                color: customColorSchemeTokens.colorContentCodePreviewC
            }
        },
        {
            types: ['atrule', 'keyword'],
            style: {
                color: customColorSchemeTokens.colorContentCodePreviewA
            }
        },
        {
            types: ['attr-value', 'number', 'function'],
            style: {
                color: customColorSchemeTokens.colorContentCodePreviewD
            }
        },
        {
            types: ['regex', 'important', 'variable'],
            style: {
                color: tokens.colorContentStatic
            }
        },
        {
            types: ['important', 'bold'],
            style: {
                color: tokens.colorContentStaticInverse,
                fontWeight: 'bold'
            }
        }
    ]
}

export default theme
