import type {CustomColorSchemeToken} from '@pleo-io/telescope'
import {createCustomColorSchemeTokensGlobalStyle, tokens} from '@pleo-io/telescope'

// Custom color scheme tokens
export const customColorSchemeTokens = {
    colorContentCodePreviewA: 'var(--colorContentCodePreviewA)',
    colorContentCodePreviewB: 'var(--colorContentCodePreviewB)',
    colorContentCodePreviewC: 'var(--colorContentCodePreviewC)',
    colorContentCodePreviewD: 'var(--colorContentCodePreviewD)',
    colorBackgroundExample: 'var(--colorBackgroundExample)',
    colorBackgroundWarning: 'var(--colorBackgroundWarning)',
    colorBorderNavigationLinkItems: 'var(--colorBorderNavigationLinkItems)',
    colorBorderStaticLoudTokenExample: 'var(--colorBorderStaticLoudTokenExample)',
    colorBorderStaticTokenExample: 'var(--colorBorderStaticTokenExample)'
} as const
type Data = Record<keyof typeof customColorSchemeTokens, CustomColorSchemeToken>
const customColorSchemeTokensData: Data = {
    colorContentCodePreviewA: {light: tokens.blue700, dark: tokens.blue600},
    colorContentCodePreviewB: {light: tokens.green800, dark: tokens.green600},
    colorContentCodePreviewC: {light: tokens.yellow800, dark: tokens.yellow600},
    colorContentCodePreviewD: {light: tokens.pink800, dark: tokens.pink600},
    colorBackgroundExample: {light: tokens.pink500, dark: tokens.pink800},
    colorBackgroundWarning: {light: tokens.yellow300, dark: tokens.yellow900},
    colorBorderNavigationLinkItems: {light: tokens.shade400, dark: tokens.shade600},
    colorBorderStaticLoudTokenExample: {light: tokens.shade600, dark: tokens.shade400},
    colorBorderStaticTokenExample: {light: tokens.shade500, dark: tokens.shade600}
} as const

export const CustomColorSchemeTokensGlobalStyle = createCustomColorSchemeTokensGlobalStyle(
    customColorSchemeTokensData
)

export const minWidthDesktop = '900px'
export const maxWidthTablet = '899px'
export const maxWidthContent = '750px'
export const paddingContentSmall = tokens.spacing28
export const paddingContentLarge = tokens.spacing56
export const fontFamilyCode =
    "SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
export const backgroundColorExample = customColorSchemeTokens.colorBackgroundExample
