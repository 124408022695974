import React from 'react'
import styled from 'styled-components'

import {Inline, tokens} from '@pleo-io/telescope'
import spacing from '@pleo-io/telescope-tokens/dist/spacing.json'

import {
    Table,
    TableHead,
    TableHeadRow,
    TableHeadData,
    TableBody,
    TableBodyRow,
    TableBodyData,
    TokenName
} from '@/components/docs/table'
import {backgroundColorExample} from '@/tokens'

const Example = styled.div<{size: number | string}>`
    width: 20px;
    height: 20px;
    background-color: ${backgroundColorExample};
    border-radius: ${tokens.arc4};
`

export const Spacing = () => {
    return (
        <Table>
            <TableHead>
                <TableHeadRow>
                    <TableHeadData>Token</TableHeadData>
                    <TableHeadData>Value</TableHeadData>
                    <TableHeadData>Example</TableHeadData>
                </TableHeadRow>
            </TableHead>
            <TableBody>
                {spacing.props.map((token) => (
                    <TableBodyRow key={token.name}>
                        <TableBodyData>
                            <TokenName {...token} />
                        </TableBodyData>
                        <TableBodyData>{token.value}</TableBodyData>
                        <TableBodyData>
                            <Inline space={parseInt(token.value, 10) as any}>
                                <Example size={token.value} />
                                <Example size={token.value} />
                            </Inline>
                        </TableBodyData>
                    </TableBodyRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default Spacing
