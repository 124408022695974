import { ComponentExample, RelatedComponents, Features, Feature, FeatureDescription } from 'docs';
import { Callout, CalloutGroup, Link } from '@pleo-io/telescope';
import { Lock } from '@pleo-io/telescope-icons';
import * as React from 'react';
export default {
  ComponentExample,
  RelatedComponents,
  Features,
  Feature,
  FeatureDescription,
  Callout,
  CalloutGroup,
  Link,
  Lock,
  React
};