import { ComponentExample, Features, Feature, RelatedComponents, FeatureDescription } from 'docs';
import { Stack, Text, CurrencyInput } from '@pleo-io/telescope';
import * as React from 'react';
export default {
  ComponentExample,
  Features,
  Feature,
  RelatedComponents,
  FeatureDescription,
  Stack,
  Text,
  CurrencyInput,
  React
};