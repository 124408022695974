import styled from 'styled-components'

import {tokens} from '@pleo-io/telescope'

export const Example = styled.div<{center?: boolean}>`
    display: flex;
    justify-content: center;
    margin: ${tokens.spacing24} 0;
    padding: ${tokens.spacing36};
    background-color: ${tokens.colorBackgroundStatic};
    border: ${tokens.borderStatic};
    border-radius: ${tokens.arc8};
`
