import {Link as GatsbyLink} from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import {Inline, Text, tokens} from '@pleo-io/telescope'

const Wrapper = styled(GatsbyLink)`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: ${tokens.colorContentStatic};
    border: ${tokens.borderStatic};
    border-radius: ${tokens.arc8};
    cursor: pointer;
    transition: ${tokens.smooth};

    &:hover {
        color: ${tokens.colorContentStatic};
        text-decoration: none;
        border-color: ${tokens.colorBorderInteractive};
        box-shadow: ${tokens.shadowRaise};
    }
`

const Illustration = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    overflow: hidden;
    padding: ${tokens.spacing24};
    background-color: ${tokens.colorBackgroundStaticLoud};
`

const Content = styled.div`
    flex: 1;

    /* border-top: ${tokens.borderStatic}; */
    padding: ${tokens.spacing24};
`

const TitleWrapper = styled(Inline).attrs({space: 8, alignItems: 'center', flexWrap: 'wrap'})`
    margin-bottom: ${tokens.spacing8};
`

const Title = styled(Text).attrs({variant: 'xlarge-accent', weight: 'semibold'})``

const Description = styled(Text).attrs({variant: 'medium-default'})`
    color: ${tokens.colorContentStaticQuiet};
`

type Props = {
    title: string
    description: string
    href: string
    illustration?: any
    titleBadge?: any
}
export const Card = ({title, description, illustration, href, titleBadge}: Props) => (
    <Wrapper to={href}>
        {illustration && <>{illustration}</>}
        <Content>
            <TitleWrapper>
                <Title>{title}</Title>
                {titleBadge}
            </TitleWrapper>
            <Description>{description}</Description>
        </Content>
    </Wrapper>
)

Card.Illustration = Illustration
