import { Features, Feature, ComponentExample, RelatedComponents, FeatureDescription } from 'docs';
import { Link as GatsbyLink } from 'gatsby';
import { Link } from '@pleo-io/telescope';
import { ArrowLeft, ArrowRight } from '@pleo-io/telescope-icons';
import * as React from 'react';
export default {
  Features,
  Feature,
  ComponentExample,
  RelatedComponents,
  FeatureDescription,
  GatsbyLink,
  Link,
  ArrowLeft,
  ArrowRight,
  React
};