import { ComponentExample, Features, Feature, FeatureDescription, RelatedComponents } from 'docs';
import { Button, ButtonGroup, Tooltip } from '@pleo-io/telescope';
import { Plus, ArrowRight } from '@pleo-io/telescope-icons';
import * as React from 'react';
export default {
  ComponentExample,
  Features,
  Feature,
  FeatureDescription,
  RelatedComponents,
  Button,
  ButtonGroup,
  Tooltip,
  Plus,
  ArrowRight,
  React
};