import lifecycle from '@/assets/about/lifecycle.png';
import featuresystem from '@/assets/about/featuresystem.png';
import localsystem from '@/assets/about/localsystem.png';
import coresystem from '@/assets/about/coresystem.png';
import { Link } from '@pleo-io/telescope';
import * as React from 'react';
export default {
  lifecycle,
  featuresystem,
  localsystem,
  coresystem,
  Link,
  React
};