import {navigate, Link as GatsbyLink} from 'gatsby'
import React from 'react'
import styled, {css} from 'styled-components'

import {Button, Link, Text, tokens} from '@pleo-io/telescope'
import {
    Box,
    CategoryEquipment,
    CategoryNone,
    Lightbulb,
    Puzzle,
    User
} from '@pleo-io/telescope-icons'

import landingPageIllustration from '@/assets/landing.svg'
import {minWidthDesktop} from '@/tokens'

const paddingSmall = tokens.spacing36
const paddingLarge = '84px'

const breakpointHorizontalHeaderLayout = '1150px'

const paddingStyles = css`
    padding: ${paddingSmall};

    @media (min-width: ${breakpointHorizontalHeaderLayout}) {
        padding: ${paddingLarge};
    }
`

const CardWrapper = styled(GatsbyLink)`
    display: block;
    padding: ${tokens.spacing36};
    text-decoration: none !important;
    border: ${tokens.borderStatic};
    border-radius: ${tokens.arc12};
    transition: ${tokens.smooth};

    &:hover {
        box-shadow: ${tokens.shadowRaise};
    }

    svg {
        margin-right: ${tokens.spacing8};
    }
`

const CardHeading = styled(Text).attrs({variant: '2xlarge-accent', weight: 'semibold', space: 20})`
    display: flex;
    align-items: center;
`

const FeatureCard = ({illustration, title, description, href}: any) => {
    return (
        <CardWrapper to={href}>
            <CardHeading>
                {illustration}
                {title}
            </CardHeading>
            <Text variant="large-accent">{description}</Text>
        </CardWrapper>
    )
}

const NotionLogo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none">
        <path
            d="M6.017 4.313l55.333 -4.087c6.797 -0.583 8.543 -0.19 12.817 2.917l17.663 12.443c2.913 2.14 3.883 2.723 3.883 5.053v68.243c0 4.277 -1.553 6.807 -6.99 7.193L24.467 99.967c-4.08 0.193 -6.023 -0.39 -8.16 -3.113L3.3 79.94c-2.333 -3.113 -3.3 -5.443 -3.3 -8.167V11.113c0 -3.497 1.553 -6.413 6.017 -6.8z"
            fill="#fff"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.35 0.227l-55.333 4.087C1.553 4.7 0 7.617 0 11.113v60.66c0 2.723 0.967 5.053 3.3 8.167l13.007 16.913c2.137 2.723 4.08 3.307 8.16 3.113l64.257 -3.89c5.433 -0.387 6.99 -2.917 6.99 -7.193V20.64c0 -2.21 -0.873 -2.847 -3.443 -4.733L74.167 3.143c-4.273 -3.107 -6.02 -3.5 -12.817 -2.917zM25.92 19.523c-5.247 0.353 -6.437 0.433 -9.417 -1.99L8.927 11.507c-0.77 -0.78 -0.383 -1.753 1.557 -1.947l53.193 -3.887c4.467 -0.39 6.793 1.167 8.54 2.527l9.123 6.61c0.39 0.197 1.36 1.36 0.193 1.36l-54.933 3.307 -0.68 0.047zM19.803 88.3V30.367c0 -2.53 0.777 -3.697 3.103 -3.893L86 22.78c2.14 -0.193 3.107 1.167 3.107 3.693v57.547c0 2.53 -0.39 4.67 -3.883 4.863l-60.377 3.5c-3.493 0.193 -5.043 -0.97 -5.043 -4.083zm59.6 -54.827c0.387 1.75 0 3.5 -1.75 3.7l-2.91 0.577v42.773c-2.527 1.36 -4.853 2.137 -6.797 2.137 -3.107 0 -3.883 -0.973 -6.21 -3.887l-19.03 -29.94v28.967l6.02 1.363s0 3.5 -4.857 3.5l-13.39 0.777c-0.39 -0.78 0 -2.723 1.357 -3.11l3.497 -0.97v-38.3L30.48 40.667c-0.39 -1.75 0.58 -4.277 3.3 -4.473l14.367 -0.967 19.8 30.327v-26.83l-5.047 -0.58c-0.39 -2.143 1.163 -3.7 3.103 -3.89l13.4 -0.78z"
            fill="#000"
        />
    </svg>
)

const SlackLogo = () => (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22.17,16.66H14.83a1.83,1.83,0,0,1,0-3.66h7.34a1.83,1.83,0,0,1,0,3.66Z"
            fill="currentColor"
        ></path>
        <path
            d="M14.83,11A1.83,1.83,0,0,1,13,9.17V1.83a1.83,1.83,0,1,1,3.65,0V9.17A1.83,1.83,0,0,1,14.83,11Z"
            fill="currentColor"
        ></path>
        <path
            d="M9.17,11H1.83a1.83,1.83,0,0,1,0-3.66H9.17a1.83,1.83,0,0,1,0,3.66Z"
            fill="currentColor"
        ></path>
        <path
            d="M9.17,24a1.83,1.83,0,0,1-1.83-1.83V14.83a1.83,1.83,0,1,1,3.65,0v7.34A1.83,1.83,0,0,1,9.17,24Z"
            fill="currentColor"
        ></path>
        <path
            d="M2.22,17.48A2.22,2.22,0,0,1,0,15.27,2.27,2.27,0,0,1,2.27,13H3.75a.74.74,0,0,1,.73.74v1.48A2.26,2.26,0,0,1,2.22,17.48Z"
            fill="currentColor"
        ></path>
        <path
            d="M10.27,4.48H8.78A2.26,2.26,0,0,1,6.52,2.22,2.21,2.21,0,0,1,8.74,0,2.27,2.27,0,0,1,11,2.27V3.75A.73.73,0,0,1,10.27,4.48Z"
            fill="currentColor"
        ></path>
        <path
            d="M15.26,24A2.27,2.27,0,0,1,13,21.73V20.25a.73.73,0,0,1,.73-.73h1.49a2.26,2.26,0,0,1,2.26,2.26A2.21,2.21,0,0,1,15.26,24Z"
            fill="currentColor"
        ></path>
        <path
            d="M21.73,11H20.25a.74.74,0,0,1-.73-.74V8.78a2.26,2.26,0,0,1,2.26-2.26A2.22,2.22,0,0,1,24,8.73,2.27,2.27,0,0,1,21.73,11Z"
            fill="currentColor"
        ></path>
    </svg>
)

const LinearLogo = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        x="0"
        y="0"
        version="1.1"
        viewBox="0 0 50 50"
    >
        <path d="M41.3 44c.5-.4.9-.8 1.3-1.3 9.8-9.8 9.8-25.6 0-35.4-9.8-9.8-25.6-9.8-35.4 0-.4.5-.8.9-1.2 1.4L41.3 44zM38.3 46.2 3.8 11.7c-.7 1.1-1.3 2.2-1.8 3.4L34.9 48c1.2-.5 2.3-1.1 3.4-1.8zM31.1 49.3.7 18.9c-.4 1.5-.6 3-.7 4.5L26.6 50c1.5-.1 3-.3 4.5-.7zM21.1 49.8.2 28.9c.8 5.1 3.1 9.9 7 13.9 4 3.8 8.9 6.2 13.9 7z" />
    </svg>
)

export const Wrapper = styled.div`
    max-width: 1300px;
    margin: 0 auto;
`

const Hero = styled.div`
    text-align: center;

    @media (min-width: ${breakpointHorizontalHeaderLayout}) {
        flex: 0 0 510px;
        padding-right: ${tokens.spacing24};
        text-align: left;
    }
`

const Title = styled(Text).attrs({weight: 'bold'})`
    margin: ${tokens.spacing18} 0;
    font-size: ${tokens.font4XLarge};

    @media (width >= 600px) {
        font-size: ${tokens.font5XLarge};
    }

    @media (min-width: ${breakpointHorizontalHeaderLayout}) {
        margin: 0;
        /* stylelint-disable-next-line declaration-property-value-allowed-list */
        font-size: 56px;
    }
`

const Intro = styled(Text).attrs({weight: 'semibold'})`
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    line-height: 1.5;

    &,
    a {
        font-size: ${tokens.font2XLarge};

        @media (width >= 600px) {
            font-size: ${tokens.font4XLarge};
        }
    }

    span {
        color: ${tokens.pink700};
    }
`

const Header = styled.div`
    ${paddingStyles};
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    @media (min-width: ${breakpointHorizontalHeaderLayout}) {
        flex-direction: row;
    }
`

const Illustration = styled.div`
    flex: 1;

    img {
        width: 100%;
        max-width: 420px;
        margin-left: auto;

        @media (width >= 1400px) {
            max-width: 580px;
        }
    }
`

const LearnMoreText = styled(Text).attrs({variant: '2xlarge-accent'})`
    margin-top: ${tokens.spacing60};
    text-align: center;
`

const Body = styled.div`
    ${paddingStyles};
    padding-top: 0 !important;
`

const Features = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${tokens.spacing36};

    @media (width >= 600px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: ${minWidthDesktop}) {
        grid-template-columns: 1fr;
    }

    @media (min-width: ${breakpointHorizontalHeaderLayout}) {
        grid-template-columns: 1fr 1fr;
    }

    @media (width >= 1400px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`

const Footer = styled.footer`
    ${paddingStyles};
    display: grid;
    grid-template-columns: 1fr;
    gap: ${tokens.spacing48};
    border-top: ${tokens.borderStatic};

    @media (width >= 1150px) {
        grid-template-columns: 1fr 1fr;
    }
`

const Ul = styled.ul``
const Li = styled.li`
    display: flex;
    align-items: center;
    font-size: ${tokens.fontXLarge};

    &:not(:last-child) {
        margin-bottom: ${tokens.spacing10};
    }

    svg {
        width: 18px;
        margin-right: ${tokens.spacing8};
        fill: currentColor;
    }

    a,
    a:visited,
    a:hover {
        color: ${tokens.colorContentStatic};
    }
`

export const LandingPage = () => (
    <Wrapper>
        <Header>
            <Hero>
                <Title>Telescope.</Title>
                <Intro>
                    Design, build, and ship coherent experiences with{' '}
                    <Link inherit href="https://www.pleo.io/">
                        Pleo's
                    </Link>{' '}
                    design system.
                </Intro>
            </Hero>
            <Illustration>
                <img alt="illustration" src={landingPageIllustration} />
            </Illustration>
        </Header>
        <Body>
            <Features>
                <FeatureCard
                    href="/foundations/overview/overview.overview"
                    illustration={<Lightbulb size={32} />}
                    title="Foundations"
                    description="Our shared practices and design decisions captured and clarified"
                />
                <FeatureCard
                    href="/tokens/overview/overview.overview"
                    illustration={<CategoryNone size={32} />}
                    title="Design Tokens"
                    description="Design primitives such as colors, typography and spacing"
                />
                <FeatureCard
                    href="/components/overview/overview.overview"
                    illustration={<Box size={32} />}
                    title="Components"
                    description="Common Pleo design elements made shareable and reuseable"
                />
                <FeatureCard
                    href="/patterns/overview/overview.overview"
                    illustration={<Puzzle size={32} />}
                    title="Patterns"
                    description="How things go together and when to use what"
                />
                <FeatureCard
                    href="/icons/icons.overview"
                    illustration={<User size={32} />}
                    title="Icons"
                    description="An iconset tailored for Pleo user interfaces and experiences"
                />
                <FeatureCard
                    href="/tools/tools.overview"
                    illustration={<CategoryEquipment size={32} />}
                    title="Tools"
                    description="Such as our Chrome extension and metrics application"
                />
            </Features>
            <LearnMoreText>
                Want to know more? Let us tell you a bit{' '}
                <Link inherit href="/about/about.overview">
                    about our design system
                </Link>
                .
            </LearnMoreText>
        </Body>
        <Footer>
            <div>
                <Text variant="4xlarge-accent" weight="bold" space={10}>
                    Contribute
                </Text>
                <Text variant="2xlarge-accent" space={32}>
                    Help us make Telescope a better place by improving our code, design and
                    documentation.
                </Text>
                <Button
                    variant="primary"
                    onClick={() => navigate('/contributing/contributing.overview')}
                >
                    Start contributing
                </Button>
            </div>
            <div>
                <Text variant="4xlarge-accent" weight="bold" space={10}>
                    Get in touch
                </Text>
                <Text variant="2xlarge-accent" space={32}>
                    Telescope is built and maintained by the{' '}
                    <Link
                        inherit
                        href="https://www.notion.so/pleo/Design-Systems-3056a937379f48af8ced587aee32cec7"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Design Systems
                    </Link>{' '}
                    team at Pleo.
                </Text>
                <Ul>
                    <Li>
                        <SlackLogo />
                        <a
                            href="https://getpleo.slack.com/archives/CPCFSJK1R"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Reach out in Slack
                        </a>
                    </Li>
                    <Li>
                        <LinearLogo />
                        <a
                            href="https://linear.app/pleo/team/DO/new"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Make a request in Linear
                        </a>
                    </Li>
                    <Li>
                        <NotionLogo />
                        <a
                            href="https://www.notion.so/pleo/DesignOps-3056a937379f48af8ced587aee32cec7"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Find us in Notion
                        </a>
                    </Li>
                </Ul>
            </div>
        </Footer>
    </Wrapper>
)
